export const langs = {
    "ba": {
        "Početna": "Početna",
        "O nama": "O nama",
        "Dokumenta": "Dokumenta",
        "Galerija": "Galerija",
        "Obaveštenja": "Obaveštenja",
        "KONTAKT": "KONTAKT",
        "GALERIJA": "GALERIJA",
        "Foto galerija": "Foto galerija",
        "NAVIGACIJA": "NAVIGACIJA",
        "Kontakt": "Kontakt",
        "LOKACIJA": "LOKACIJA",
        "Sabac": "Sabac",
        "Savska 8, Srbija": "Savska 8, Srbija",
        "+381 15 306 490": "+381 15 306 490",
        "info@dpklas.rs": "info@dpklas.rs",
        "PRATITE NAS": "PRATITE NAS",
        "Osnivanje": "Osnivanje",
        "Pocetna": "Početna",
        "DOKUMENTA": "DOKUMENTA",
        "Na ovoj stranici možete preuzeti dokumenta firme Bioenergo PARTNER MD. Dokumenti su u PDF formatu i za njihovo pregledanje potreban vam je program koji omogućava pregledanje .pdf fajlova.": "Na ovoj stranici možete preuzeti dokumenta firme Bioenergo PARTNER MD. Dokumenti su u PDF formatu i za njihovo pregledanje potreban vam je program koji omogućava pregledanje .pdf fajlova.",
        "Naziv": "Naziv",
        "Sediste": "Sedište",
        "Adresa": "Adresa",
        "PIB": "PIB",
        "Maticni broj": "Matični broj",
        "Broj evidencione prijave „ PEPDV“": "Broj evidencione prijave „ PEPDV“",
        "Ime i prezime ovlašćenog lica": "Ime i prezime ovlašćenog lica",
        "Telefon": "Telefon",
        "Fax": "Fax",
        "E-mail": "E-mail",
        "Web": "Web",
        "Podaci o firmi": "Podaci o firmi",
        "OBAVEŠTENJA": "OBAVEŠTENJA",
        "Novosti i obaveštenja": "Novosti i obaveštenja",
        "Obaveštenja iz naše kompanije": "Obaveštenja iz naše kompanije",
        "Kontakt informacije": "Kontakt informacije",
        "Pošaljite nam poruku": "Pošaljite nam poruku",
        "Ime i prezime *": "Ime i prezime *",
        "Email *": "Email *",
        "Vasa poruka *": "Vaša poruka *",
        "POŠALJI PORUKU": "POŠALJI PORUKU"
    },
    "en": {
        "Početna": "Home",
        "O nama": "About Us",
        "Dokumenta": "Documents",
        "Galerija": "Gallery",
        "Obaveštenja": "Blog",
        "KONTAKT": "CONTACT",
        "GALERIJA": "GALLERY",
        "Foto galerija": "Photo gallery",
        "NAVIGACIJA": "NAVIGATION",
        "Kontakt": "Contact",
        "LOKACIJA": "LOCATION",
        "Sabac": "Sabac",
        "Savska 8, Srbija": "Savska 8, Srbija",
        "+381 15 306 490": "+381 15 306 490",
        "info@dpklas.rs": "info@dpklas.rs",
        "PRATITE NAS": "FOLLOW US",
        "Osnivanje": "Establishment",
        "Pocetna": "Home",
        "DOKUMENTA": "DOCUMENTS",
        "Na ovoj stranici možete preuzeti dokumenta firme Bioenergo PARTNER MD. Dokumenti su u PDF formatu i za njihovo pregledanje potreban vam je program koji omogućava pregledanje .pdf fajlova.": "On this page you can download Bioenergo PARTNER MD documents. The documents are in PDF format and to view them you need a program that allows you to view .pdf files.",
        "Naziv": "Name",
        "Sediste": "Company headquarters",
        "Adresa": "Address",
        "PIB": "PIB",
        "Maticni broj": "Registration number",
        "Broj evidencione prijave „ PEPDV“": "Registration number „ PEPDV“",
        "Ime i prezime ovlašćenog lica": "Name and surname of the authorized person",
        "Telefon": "Telephone",
        "Fax": "Fax",
        "E-mail": "E-mail",
        "Web": "Web",
        "Podaci o firmi": "Company information",
        "OBAVEŠTENJA": "BLOG",
        "Novosti i obaveštenja": "News and announcements",
        "Obaveštenja iz naše kompanije": "Notifications from our company",
        "Kontakt informacije": "Contact informations",
        "Pošaljite nam poruku": "Send us a message",
        "Ime i prezime *": "Name *",
        "Email *": "Email *",
        "Vasa poruka *": "Your message *",
        "POŠALJI PORUKU": "SEND MESSAGE",
        " - BIO ENERGO | Pratimo prirodu, stvaramo energiju.": " - BIO ENERGO | We follow nature, we create energy.",
        "Bioenergo PARTNER MD je biogasno postrojenje koje koristi resurse sela kao izvor za proizvodnju električne i toplotne energije.":"Bioenergo PARTNER MD is a biogas plant that uses rural resources as a source for electricity and heat production.",
        "Novosti": "Blog"
    }
}