import { matchPath } from 'react-router-dom';


const allLinks = [
    '/',
    '/o-nama/:alias',
    '/o-nama',
    '/novosti',
    '/novosti/:alias',
    '/kontakt',
    '/galerija',
    '/dokumenta',
]

const linkTranslates = {
  
    'en': {
        '/': '/en',
        '/o-nama/:alias': '/en/about-us/:alias',
        '/o-nama': '/en/about-us',
        '/novosti': '/en/blog',
        '/novosti/:alias': '/en/blog/:alias',
        '/kontakt': '/en/contact',
        '/galerija': '/en/gallery',
        '/dokumenta': '/en/documents'
    },
}

export default function (link, lang){
    let to = link;
    if (!lang){
        lang = 'ba';
    }

    if (lang !== 'ba') {
        allLinks.some(route => {

            const match = matchPath(to, route);
            if (match && match.isExact) {
                to = to.replace(route.split(':')[0], linkTranslates[lang][route].split(':')[0])
            }
            return match && match.isExact;
        });
    }


    return to;
}