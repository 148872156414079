import React, { Component } from 'react'
import Link from '../components/link'

import Isvg from 'react-inlinesvg'
import Page from '../containers/page'

import { Container, Row, Col, Button } from 'reactstrap'

import FormBuilder from '../components/forms/formBuilder'
import { required } from '../components/forms/validation'
import { API_ENDPOINT } from '../constants'

import logo from '../assets/images/logo.png';
import ee_slika from '../assets/images/ee_slika.png';

import zajednica from '../assets/images/zajednica.png';


import email from '../assets/profile-svg/email.svg';
import facebook from '../assets/profile-svg/facebook.svg';
import info from '../assets/profile-svg/info.svg';
import instagram from '../assets/profile-svg/instagram.svg';
import linkedin from '../assets/profile-svg/linkedin.svg';
import mobPhone from '../assets/profile-svg/mobPhone.svg';
import phone from '../assets/profile-svg/phone.svg';
import profile from '../assets/profile-svg/profile.svg';
import webSite from '../assets/profile-svg/webSite.svg';

import headerLayout from '../assets/profile-svg/header-layout.png';
import eeLogo from '../assets/profile-svg/ee_logo.svg';




class PreviewUserProfile extends Component {
    constructor(props) {
        super(props)

        this.state = {
            ...props.initialData,
            userProfile: null,
            settings: null
        }
    }

    componentDidMount() {
        if (typeof window !== 'undefined') {
            window.scrollTo(0, 0)
        }
        if (this.props[0].location.search) {
            let search = this.props[0].location.search;

            let splitSearch = search.split('=')
            if (splitSearch && splitSearch.length == 2) {
                let id = splitSearch[1];
                if (id) {

                    fetch(API_ENDPOINT + '/data/users/or/directors/' + id, {
                        method: 'GET',
                        headers: {
                            'content-type': 'application/json',
                            Authorization:
                                typeof localStorage !== 'undefined'
                                    ? `Bearer ${localStorage.getItem('authToken')}`
                                    : null,
                        },
                    }).then((res) => res.json()).then((result) => {
                        this.setState({ userProfile: result })
                    })
                }
            }
        }
        fetch(API_ENDPOINT + '/data/home', {
            method: 'GET',
            headers: {
                'content-type': 'application/json',
                Authorization:
                    typeof localStorage !== 'undefined'
                        ? `Bearer ${localStorage.getItem('authToken')}`
                        : null,
            },
        }).then((res) => res.json()).then((result) => {
            this.setState({ settings: result })
        })



    }

    saveContact = () => {
        if (this.state.userProfile && this.state.userProfile._id) {
            fetch(API_ENDPOINT + '/data/users/vcard/' + this.state.userProfile._id, {
                method: 'GET',
                headers: {
                    'content-type': 'application/json',
                    Authorization:
                        typeof localStorage !== 'undefined'
                            ? `Bearer ${localStorage.getItem('authToken')}`
                            : null,
                },
            }).then((res) => res.json()).then((result) => {
                if (result && result.fileName) {
                    if (typeof window != 'undefined') {
                        // window.open(API_ENDPOINT + result.fileName)
                        var link = document.createElement("a");
                        link.download = `${result.fileName}`;
                        link.target = "_blank";
          
                        // Construct the URI
                        link.href = API_ENDPOINT + result.fileName;
                        document.body.appendChild(link);
                        link.click();
                    }
                }
                // this.setState({ userProfile: result })
            })
        }



    }


    render() {
        let userProfile = this.state.userProfile;
        if (!userProfile) {
            return null;
        }
        let facebookLink = this.state.settings && this.state.settings.facebookLink ? this.state.settings.facebookLink : null;
        let instagramkLink = this.state.settings && this.state.settings.instagramLink ? this.state.settings.instagramLink : null;
        let linkedinLink = this.state.settings && this.state.settings.liknedInLink ? this.state.settings.liknedInLink : null;
        let webSiteLink = this.state.settings && this.state.settings.webSiteLink ? this.state.settings.webSiteLink : null;
        return (
            <div>
                <div className='preview-user-profile-header'>
                    <div className='layout'>
                        <img src={headerLayout} />
                    </div>
                    <div className='logo'>
                        <Isvg src={eeLogo} />
                    </div>
                </div>
                <div className='preview-user-profile-wrap'>

                    <Container>
                        <Row>
                            <Col lg='12' className='right'>

                                <div className='preview-user-profile'>

                                    <div className='header-image'>
                                        <img src={ee_slika} />
                                    </div>

                                    <div className='user-main-info'>
                                        <div className='image'>
                                            {/* <img src={zajednica} /> */}
                                            {
                                                userProfile.image ?
                                                    <img src={API_ENDPOINT + userProfile.image} />
                                                    :
                                                    <Isvg src={profile} />
                                            }
                                        </div>
                                        {
                                            userProfile.name ?
                                                <h6>{userProfile.name}</h6>
                                                :
                                                null
                                        }
                                        {
                                            userProfile.position ?
                                                <p>{userProfile.position}</p>
                                                :
                                                null
                                        }

                                    </div>
                                    <div className='dashed-span'></div>
                                    <div className='user-other-info'>
                                        {
                                            userProfile.mobilePhone ?
                                                <div style={{ marginBottom: 10 }}>
                                                    <a href={`tel:${userProfile.mobilePhone}`}>
                                                        <div className='info-field-wrap'>
                                                            <div className='icon-wrap'>
                                                                <Isvg src={mobPhone} />
                                                            </div>
                                                            <h5>Mobilni telefon</h5>

                                                        </div>
                                                    </a>
                                                </div>
                                                :
                                                <div className='info-field-wrap'>
                                                    <div className='icon-wrap'>
                                                        <Isvg src={mobPhone} />
                                                    </div>
                                                    <h5>Mobilni telefon</h5>

                                                </div>
                                        }
                                        {
                                            userProfile.phone ?
                                                <div style={{ marginBottom: 10 }}>
                                                    <a href={`tel:${userProfile.phone}`}>
                                                        <div className='info-field-wrap'>
                                                            <div className='icon-wrap'>
                                                                <Isvg src={phone} />
                                                            </div>
                                                            <h5>Telefon</h5>

                                                        </div>
                                                    </a>
                                                </div>
                                                :
                                                <div className='info-field-wrap'>
                                                    <div className='icon-wrap'>
                                                        <Isvg src={phone} />
                                                    </div>
                                                    <h5>Telefon</h5>

                                                </div>
                                        }



                                        {
                                            userProfile.email ?
                                                <a href={`mailto:${userProfile.email}`}>
                                                    <div className='info-field-wrap'>
                                                        <div className='icon-wrap'>
                                                            <Isvg src={email} />
                                                        </div>
                                                        <h5>E-Mail</h5>

                                                    </div>
                                                </a>
                                                :
                                                <div className='info-field-wrap'>
                                                    <div className='icon-wrap'>
                                                        <Isvg src={email} />
                                                    </div>
                                                    <h5>E-Mail</h5>

                                                </div>
                                        }



                                    </div>

                                </div>
                                <div className='social-networks-wrap'>
                                    <div className='social-networks-item' onClick={() => {
                                        if (facebookLink && typeof window != 'undefined') {
                                            window.open(facebookLink)
                                        }
                                    }}>
                                        <Isvg src={facebook} />
                                    </div>

                                    <div className='social-networks-item' onClick={() => {
                                        if (instagramkLink && typeof window != 'undefined') {
                                            window.open(instagramkLink)
                                        }
                                    }}>
                                        <Isvg src={instagram} />
                                    </div>

                                    <div className='social-networks-item' onClick={() => {
                                        if (linkedinLink && typeof window != 'undefined') {
                                            window.open(linkedinLink)
                                        }
                                    }}>
                                        <Isvg src={linkedin} />
                                    </div>

                                    <div className='social-networks-item' onClick={() => {
                                        if (webSiteLink && typeof window != 'undefined') {
                                            window.open(webSiteLink)
                                        }
                                    }}>
                                        <Isvg src={webSite} />
                                    </div>

                                </div>


                                <div className='save-button-wrap'>
                                    <a href={API_ENDPOINT + '/data/users/vcard/' + this.state.userProfile._id} target="_blank">
                                    <Button color="primary">
                                        Sačuvaj kontakt
                                    </Button>
                                    </a>
                                </div>

                                <div className='euroexpress-info-box'>
                                    <p>www.euroexpress.ba</p>
                                    <img src={info} />
                                </div>



                                {/* <div class='main-form-container'>
                                <div class='logo'>
                                    <img src={logo} />
                                </div>
                                {
                                    userProfile ?
                                        <div className='user-profile-wrap'>
                                            {
                                                userProfile.name ?
                                                    <div className='user-profile-field'>
                                                        <div className='image-wrap'><img src={API_ENDPOINT + userProfile.image}/></div>
                                                    </div>
                                                    :
                                                    null
                                            }
                                            {
                                                userProfile.name ?
                                                    <div className='user-profile-field'>{userProfile.name}</div>
                                                    :
                                                    null
                                            }
                                            {
                                                userProfile.position ?
                                                    <div className='user-profile-field'>{userProfile.position}</div>
                                                    :
                                                    null
                                            }

                                            <div className='subtitle'>
                                                <h4>Kontakt podaci</h4>
                                            </div>
                                            {
                                                userProfile.email ?
                                                    <div className='user-profile-field'>{userProfile.email}</div>
                                                    :
                                                    null
                                            }

                                            {
                                                userProfile.phone ?
                                                    <div className='user-profile-field'>{userProfile.phone}</div>
                                                    :
                                                    null
                                            }
                                            {
                                                userProfile.mobilePhone ?
                                                    <div className='user-profile-field'>{userProfile.mobilePhone}</div>
                                                    :
                                                    null
                                            }

                                        </div>
                                        :
                                        null
                                }

                            </div> */}
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>


        )
    }
}

export default PreviewUserProfile
