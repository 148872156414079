
import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';

import Isvg from 'react-inlinesvg';

import { FormGroup, Label, Input, FormFeedback, FormText, Button, Row, Col } from 'reactstrap';


class Text extends Component {
    constructor(props) {
        super(props);

        this.state = {
            filter: null
        };
    }






    render() {

        const chars = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z', 'Å', 'Ä', 'Ö'];

        return (
            <FormGroup>
                {this.props.label ? <Label size="sm" className={this.props.error ? "required" : ""}>{this.props.label}{this.props.haveValidation ? <span className="required-label-span">*</span> : null}</Label> : null}
                {
                    this.props.beforeText ? <span className="field-before-text">{this.props.beforeText}</span> : null
                }

                <div className="letters-helper">
                    <Button type="button" type="button" color={this.state.filter === null ? 'primary' : 'secondary'} onClick={() => this.setState({ filter: null })} size="sm"> Allt </Button>
                    {
                        chars.map((item, idx) => {
                            return (
                                <Button color={this.state.filter === item ? 'primary' : 'secondary'} onClick={() => this.setState({ filter: item })} size="sm" key={idx}>{item}</Button>
                            )
                        })
                    }
                </div>

                {/*<Input size="md" disabled={this.props.disabled} name={this.props.name} invalid={this.props.error ? true : false} type={this.props.type ? this.props.type : 'text'} multiple={this.props.multiple} onChange={(val) => {
                    this.props.onChange(val)
                }} value={this.props.value} placeholder={this.props.placeholder}>
                    {this.props.values && this.props.values.length ? this.props.values.map((option, oidx) => {
                        if (!this.state.filter || (this.state.filter && option.name.indexOf(this.state.filter) == 0))
                        return (
                            <option key={oidx} value={option.value}>{option.name}</option>
                        )
                    })
                        :
                        null
                    }

                </Input>
                */}

                <Row className="multiselect-checkboxes">
                    {this.props.values && this.props.values.length ? this.props.values.map((option, oidx) => {
                        if (!this.state.filter || (this.state.filter && option.name.indexOf(this.state.filter) == 0))
                            return (
                                <Col lg="4">
                                    <Input checked={this.props.value && this.props.value.indexOf(option.value) !== -1} onChange={() => {
                                        let value = this.props.value;
                                        if (!value){
                                            value = [];
                                        }

                                        let idx = value.indexOf(option.value);
                                        if (idx == -1){
                                            value.push(option.value);
                                        }else{
                                            value.splice(idx, 1);
                                        }

                                        this.props.onChange(value);
                                        this.forceUpdate();

                                    }}  type="checkbox" /><span onClick={() => {
                                        let value = this.props.value;
                                        if (!value){
                                            value = [];
                                        }

                                        let idx = value.indexOf(option.value);
                                        if (idx == -1){
                                            value.push(option.value);
                                        }else{
                                            value.splice(idx, 1);
                                        }

                                        this.props.onChange(value);
                                        this.forceUpdate();

                                    }} className="field-after-text"> {option.name} </span>
                                </Col>
                            )
                    })
                        :
                        null
                    }
                </Row>
                {
                    this.props.afterText ? <span className="field-after-text">{this.props.afterText}</span> : null
                }
                {this.props.error ? <FormFeedback >{this.props.error}</FormFeedback> : null}
            </FormGroup>
        );
    }
}

export default Text;