import React from 'react';

import {
    Modal, ModalHeader, ModalBody, ModalFooter, Button
} from 'reactstrap';

import error from '../assets/images/error.png';

class DeleteModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {}
    }

    render() {
        console.log(this.props)
        return (

            <Modal isOpen={this.props.isOpen} toggle={this.props.toggle} >

                <ModalBody className="delete-modal">
                    <img src={error} />
                    <div className="message">
                        {
                            this.props.children
                        }
                    </div>
                </ModalBody>
                <ModalFooter>
                    <Button type="submit" color="danger" onClick={() => this.props.handler()}>{'Yes'.translate(this.props.lang)}</Button>{' '}
                    <Button type="submit" color="primary" onClick={() => this.props.toggle()} >{'No'.translate(this.props.lang)}</Button>{' '}
                </ModalFooter>
            </Modal>
        )
    }
}

export default DeleteModal;