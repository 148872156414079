import React, { Component } from 'react'
import Page from '../containers/page'

import { Container, Row, Col, Input } from 'reactstrap'

import Isvg from 'react-inlinesvg'

import FormBuilder from '../components/forms/formBuilder'
import { required } from '../components/forms/validation'
import Search from '../components/search'

import notification from '../assets/svg/notification.svg'
import profile from '../assets/svg/profile-image.svg'
import moreIcon from '../assets/svg/more.svg'
import closeIcon from '../assets/svg/x.svg'
import rightIcon from '../assets/svg/arrow-right.svg'

import gallery1 from '../assets/images/g1.jpg'
import uploadButton from '../assets/svg/upload.svg'

import ListBuilder from '../components/listBuilder'
import DeleteModal from '../components/deleteModal'
import {
  getSearchParams,
  generateSearchLink,
  generateSearchLinkMultiple,
} from '../helpers/linkHelper'
import { API_ENDPOINT } from '../constants'

class Users extends Component {
  constructor(props) {
    super(props)

    this.state = {
      ...props.initialData,
      form: false,
      useState: false,
      params: {
        entries: 10,
        page: 0,
      },
      items: [],
      areas: [],
      total: 0,
      loading: true,
      category: [],
    }
  }

  get = () => {
    for (let i = 0; i < this.props.loadData.length; i++) {
      this.props.loadData[i](
        window.fetch,
        this.props.lang,
        this.props[0].match,
        this.state.useState
          ? this.state.params
          : getSearchParams(this.props[0].location.search, {
            entries: 10,
            page: 0,
          })
      ).then((data) => {
        this.setState(
          {
            ...data,
            loading: null,
          },
          () => {
            this.props.updateMeta(
              this.props.generateSeoTags(this.state, this.props.lang)
            )
          }
        )
      })
    }

    for (let i = 0; i < this.props.loadDataWithQuery.length; i++) {
      this.props.loadDataWithQuery[i](
        window.fetch,
        this.props.lang,
        this.props[0].match,
        this.state.useState
          ? this.state.params
          : getSearchParams(this.props[0].location.search, {
            entries: 10,
            page: 0,
          })
      ).then((data) => {
        this.setState(
          {
            ...data,
            loading: null,
          },
          () => {
            this.props.updateMeta(
              this.props.generateSeoTags(this.state, this.props.lang)
            )
          }
        )
      })
    }
  }

  componentDidMount() {
    if (typeof window !== 'undefined') {
      window.scrollTo(0, 0)
    }

    this.get()
  }

  componentDidUpdate(prevProps) {
    if (prevProps[0].location.search != this.props[0].location.search) {
      this.setState(
        {
          loading: true,
        },
        () => {
          this.get()
        }
      )
    }
  }

  updateSort = (field, type) => {
    if (this.state.useState) {
      let state = this.state.params
      state.sortField = field
      state.sortType = type
      this.setState({
        params: state,
      })
    } else {
      this.props[0].history.push(
        generateSearchLinkMultiple(
          this.props[0].location.search,
          { entries: 10, page: 0 },
          [
            { name: 'sortField', value: field },
            { name: 'sortType', value: type },
          ],
          false
        )
      )
    }
  }

  updateParams = (name, value, restart = false) => {
    if (this.state.useState) {
      if (restart) {
        let obj = {
          entries: 10,
          page: 0,
        }
        obj[name] = value
        this.setState({ params: obj })
      } else {
        let obj = this.state.params
        obj[name] = value
        this.setState({ params: obj })
      }
    } else {
      this.props[0].history.push(
        generateSearchLink(
          this.props[0].location.search,
          { entries: 10, page: 0 },
          name,
          value,
          restart
        )
      )
    }
  }

  updateMultipleParams = (fields, restart = false) => {
    if (this.state.useState) {
      if (restart) {
        let obj = {
          entries: 10,
          page: 0,
        }
        for (let i = 0; i < fields.length; i++) {
          obj[fields[i].name] = fields[i].value
        }
        this.setState({ params: obj })
      } else {
        let obj = this.state.params
        for (let i = 0; i < fields.length; i++) {
          obj[fields[i].name] = fields[i].value
        }
        this.setState({ params: obj })
      }
    } else {
      this.props[0].history.push(
        generateSearchLinkMultiple(
          this.props[0].location.search,
          { entries: 10, page: 0 },
          fields,
          restart
        )
      )
    }
  }

  insertOrUpdate = (data) => {
    this.setState(
      {
        loading: true,
      },
      () => {
        if (!this.props[0].match.params.id) {
          fetch(API_ENDPOINT + '/data/news/new', {
            method: 'POST',
            headers: {
              'content-type': 'application/json',
              Authorization:
                typeof localStorage !== 'undefined'
                  ? `Bearer ${localStorage.getItem('authToken')}`
                  : null,
            },
            body: JSON.stringify(data),
          })
            .then((res) => res.json())
            .then((result) => {
              this.props[0].history.push('/news')
            })
        } else {
          fetch(API_ENDPOINT + '/data/news/' + this.props[0].match.params.id, {
            method: 'PUT',
            headers: {
              'content-type': 'application/json',
              Authorization:
                typeof localStorage !== 'undefined'
                  ? `Bearer ${localStorage.getItem('authToken')}`
                  : null,
            },
            body: JSON.stringify(data),
          })
            .then((res) => res.json())
            .then((result) => {
              this.props[0].history.push('/news')
            })
        }
      }
    )
  }

  delete = (id) => {
    this.setState(
      {
        loading: true,
      },
      () => {
        fetch(API_ENDPOINT + '/data/prize/' + id, {
          method: 'DELETE',
          headers: {
            'content-type': 'application/json',
            Authorization:
              typeof localStorage !== 'undefined'
                ? `Bearer ${localStorage.getItem('authToken')}`
                : null,
          },
        })
          .then((res) => res.json())
          .then((result) => {
            this.get()
          })
      }
    )
  }

  render() {
    let params = {}
    if (this.state.useState) {
      params = this.state.params
    } else {
      params = getSearchParams(this.props[0].location.search, {
        entries: 10,
        page: 0,
      })
    }
    return (
      <div className='page'>
        <Container className='dashboard'>
          <Row>
            <Col lg='9'>

              <Search />
            </Col>
            <Col lg='3'>
            <div className='component notifications'>
                <Isvg src={notification} />
                <div className='profile'>
                  <span className='name'>
                    {this.props.uData.username}
                  </span>
                  <Isvg src={profile} className='profile-image' />
                  <Isvg src={moreIcon} />
                </div>
              </div>
            
             {/* <div className='component notifications'>
                <Isvg src={notification} />
                <div className='profile'>
                  <span className='name'>Darko Lakic</span>
                  <Isvg src={profile} className='profile-image' />
                  <Isvg src={moreIcon} />
                </div>
              </div>



              <div className='component gallery'>
                <h5 className='component-header'>Fotografije</h5>
                <div className='gallery-row'>
                  <img src={gallery1} />
                  <img src={gallery1} />
                </div>
                <div className='gallery-row'>
                  <img src={gallery1} />
                  <img src={gallery1} />
                </div>
                <div className='gallery-row'>
                  <img src={gallery1} />
                  <img src={gallery1} />
                </div>
                <form action='/action_page.php'>
                  <input type='file' id='actual-btn' />
                  <label className='upload-button' for='actual-btn'>
                    {' '}
                    <Isvg src={uploadButton} /> <span>Izaberite slike </span> ili ih
                    prevucite ovde
                  </label>
                </form>
              </div>

              <div className='component key-words-box'>
                <h5 className='component-header'>Ključne riječi</h5>
                <div className='key-words'>
                  <div className='words'>
                    bijeljina{' '}
                    <span>
                      {' '}
                      <Isvg src={closeIcon} />{' '}
                    </span>
                  </div>
                  <div className='words'>
                    semberija{' '}
                    <span>
                      {' '}
                      <Isvg src={closeIcon} />{' '}
                    </span>
                  </div>
                  <div className='words'>
                    petjezera{' '}
                    <span>
                      {' '}
                      <Isvg src={closeIcon} />{' '}
                    </span>
                  </div>
                  <div className='words'>
                    infrastruktura{' '}
                    <span>
                      {' '}
                      <Isvg src={closeIcon} />{' '}
                    </span>
                  </div>
                </div>
              </div>

              <div className='component push-notifications'>
                <h5 className='component-header'>Puš notifikacije</h5>
                <button type='submit'>
                  Pošalji puš{' '}
                  <span>
                    {' '}
                    <Isvg src={rightIcon} />{' '}
                  </span>{' '}
                </button>
              </div>
    */}

            </Col>
            <Col lg="12">
            <FormBuilder

onSubmit={(data) => this.insertOrUpdate(data)}
initialValues={this.state.initialValues}

fields={[
  {
    type: 'row',
    children: [
      {
        type: 'col',
        width: {
          lg: 9
        },
        children: [{
          type: 'div',
          className: 'main-form',
          children: [{
            type: 'row',
            children: [
              //HEADER
              {//naslov forme
                type: 'col',
                width: {
                  lg: 3, sm: 12, xs: 12
                },
                children: [
                  {
                    type: 'div',
                    className: 'form-header',
                    children: [
                      {
                        type: 'h5',
                        className: 'component-header',
                        text: 'Dodavanje vijesti'
                      }
                    ]
                  }
                ]
              },
              {//button-i i switch-ovi
                type: 'col',
                width: {
                  lg: 9, sm: 12, xs: 12
                },
                children: [
                  {
                    type: 'div',
                    className: 'form-header-buttons',
                    children: [
                      {
                        type: 'div',
                        className: 'form-title-buttons',
                        children: [
                          {
                            type: 'switch',
                            label: 'Aktivna',
                          },
                          {
                            type: 'switch',
                            label: 'Izdvojena',
                          },
                          {
                            type: 'button',
                            className: 'main-form-button button-1',
                            text: <>Osviježi vijest{' '}
                              <span>
                                {' '}
                                <Isvg src={rightIcon} />{' '}
                              </span>{' '} </>
                          },
                          {
                            type: 'button',
                            className: 'main-form-button button-2',
                            text: <>Pregledaj vijest{' '}
                              <span>
                                {' '}
                                <Isvg src={rightIcon} />{' '}
                              </span>{' '} </>
                          }

                        ]
                      }
                    ]
                  }
                ]
              },
              //END HEADER
              //FORMA za unos vijesi
              {
                type: 'col',
                width: {
                  lg: 12, sm: 12, xs: 12
                },
                children: [
                  {
                    type: 'text',
                    name: 'title',
                    label: 'Naslov vijesti'
                  },
                ]
              },
              {
                type: 'col',
                width: {
                  lg: 12, sm: 12, xs: 12
                },
                children: [
                  {
                    type: 'text',
                    name: 'intro',
                    label: 'Uvodni tekst'
                  },
                ]
              },


              {
                type: 'col',
                width: {
                  lg: 6, sm: 12, xs: 12
                },
                children: [
                  {
                    type: 'text',
                    name: 'source',
                    label: 'Izvor'
                  },
                ]
              },
              {
                type: 'col',
                width: {
                  lg: 6, sm: 12, xs: 12
                },
                children: [
                  {
                    type: 'text',
                    name: 'photo',
                    label: 'Foto'
                  },
                ]
              },
              {
                type: 'col',
                width: {
                  lg: 12, sm: 12, xs: 12
                },
                children: [
                  {
                    type: 'html',
                    name: 'details',
                    label: 'Detaljno',
                    onChange: {}
                  },
                ]
              },
              {
                type: 'col',
                width: {
                  lg: 6, sm: 12, xs: 12
                },
                children: [
                  {
                    type: 'select',
                    name: 'categories',
                    multiple: true,
                    values: this.state.category.map(item => {
                      return {
                        name: item.categoryName,
                        value: item._id
                      }
                    }),
                    label: 'Kategorija'
                  },
                ]
              },
              {
                type: 'col',
                width: {
                  lg: 6, sm: 12, xs: 12
                },
                children: [
                  {
                    type: 'select',
                    name: 'category.subcategories',
                    multiple: true,
                    values: this.state.category.map(item => {

                      return {
                        name: item.categoryName,
                        value: item._id
                      }

                    }),
                    label: 'Podkategorija'
                  },
                ]
              },
              {
                type: 'col',
                width: {
                  lg: 6, sm: 12, xs: 12
                },
                children: [
                  {
                    type: 'submit-button',
                    className: 'main-form-button button-1',
                    text: this.props[0].match.params.id ? 'Izmjeni vijesti' : 'Dodaj vijesti',
                  }
                ]
              }

            ]
          }]
        }
        ]
      },
      //END FORM za unos vijesti
      {
        type: 'col',
        width: {
          lg: 3
        },
        children: [
          {//vrijeme objave
            type: 'div',
            className: 'component customer-age',
            children: [
              {
                type: 'h5',
                text: 'Vrijeme objave',
                className: 'component-header'
              },
              {
                type: 'date',
                name: 'date',
              },
              {
                type: 'time',
                name: 'time',

              }

            ]
          },
          {//Slike
            type: 'div',
            className: 'component gallery',
            children: [
              {
                type: 'h5',
                className: 'component-header',
                text: 'Fotografije',
              },
              {
                type: 'gallery'
              }
            ]
          },
          {/*Kljucne rijeci 
            type: 'div',
            className: 'component key-words-box',
            children: [
              {
                type: 'h5',
                text: 'Kljucne rijeci',
                className: 'component-header'
              },
              {
                type: 'div',
                className: 'key-words',
                children: [
                  {
                    type: 'tags',
                    //className: 'words',

                  }
                ]
              }
            ]
          */} ,
          {//Posalji notifikacije
            type: 'div',
            className: 'component push-notifications',
            children: [
              {
                type: 'h5',
                className: 'component-header',
                text: 'Pus notifikacije'
              },
              {
                type: 'button',
                className: 'main-form-button button-1',
                text: <>Posalji pus{' '}
                  <span>
                    {' '}
                    <Isvg src={rightIcon} />{' '}
                  </span>{' '} </>
              },
            ]
          }
        ]
      }
    ]
  }
]}
></FormBuilder>
            </Col>
            
          </Row>
        </Container>
      </div >
    )
  }
}

export default Page(Users)
