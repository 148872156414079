import React, { Component } from 'react'
import Page from '../../containers/page'

import { Container, Row, Col, Input, Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap'

import Isvg from 'react-inlinesvg'

import Search from '../../components/search'
import { Link } from 'react-router-dom'

import ListBuilder from '../../components/listBuilder'
import DeleteModal from '../../components/deleteModal'
import {
    getSearchParams,
    generateSearchLink,
    generateSearchLinkMultiple,
} from '../../helpers/linkHelper'
import { API_ENDPOINT } from '../../constants'

import garabageIcon from '../../assets/svg/garabage.svg'
import userIcon from '../../assets/svg/user-icon.svg'
import notification from '../../assets/svg/notification.svg'
import profile from '../../assets/svg/profile-image.svg'
import moreIcon from '../../assets/svg/more.svg'
import map from '../../assets/svg/map.svg'
import chart from '../../assets/svg/chart.svg'
import editIcon from '../../assets/svg/edit.svg'
import logs from '../../assets/svg/logs.svg'
import absence from '../../assets/svg/absence.svg'


import DateField from '../../components/forms/fields/date';

class List extends Component {
    constructor(props) {
        super(props)

        this.state = {
            ...props.initialData,
            form: false,
            useState: false,
            params: {
                entries: 10,
                page: 0,
            },
            items: [],
            areas: [],
            total: 0,
            loading: true,
            newsData: [],
            startDate: new Date(),
            endDate: new Date()
        }
    }

    get = () => {
        for (let i = 0; i < this.props.loadData.length; i++) {
            this.props.loadData[i](
                window.fetch,
                this.props.lang,
                this.props[0].match,
                this.state.useState
                    ? this.state.params
                    : getSearchParams(this.props[0].location.search, {
                        entries: 10,
                        page: 0,
                    })
            ).then((data) => {
                this.setState(
                    {
                        ...data,
                        loading: null,
                    },
                    () => {
                        this.props.updateMeta(
                            this.props.generateSeoTags(this.state, this.props.lang)
                        )
                    }
                )
            })
        }

        for (let i = 0; i < this.props.loadDataWithQuery.length; i++) {
            this.props.loadDataWithQuery[i](
                window.fetch,
                this.props.lang,
                this.props[0].match,
                this.state.useState
                    ? this.state.params
                    : getSearchParams(this.props[0].location.search, {
                        entries: 10,
                        page: 0,
                    })
            ).then((data) => {
                this.setState(
                    {
                        ...data,
                        loading: null,
                    },
                    () => {
                        this.props.updateMeta(
                            this.props.generateSeoTags(this.state, this.props.lang)
                        )
                    }
                )
            })
        }
    }

    componentDidMount() {
        if (typeof window !== 'undefined') {
            window.scrollTo(0, 0)
        }

        this.get()
    }

    componentDidUpdate(prevProps) {
        if (prevProps[0].location.search != this.props[0].location.search) {
            this.setState(
                {
                    loading: true,
                },
                () => {
                    this.get()
                }
            )
        }
    }

    updateSort = (field, type) => {
        if (this.state.useState) {
            let state = this.state.params
            state.sortField = field
            state.sortType = type
            this.setState({
                params: state,
            })
        } else {
            this.props[0].history.push(
                generateSearchLinkMultiple(
                    this.props[0].location.search,
                    { entries: 10, page: 0 },
                    [
                        { name: 'sortField', value: field },
                        { name: 'sortType', value: type },
                    ],
                    false
                )
            )
        }
    }

    updateParams = (name, value, restart = false) => {
        if (this.state.useState) {
            if (restart) {
                let obj = {
                    entries: 10,
                    page: 0,
                }
                obj[name] = value
                this.setState({ params: obj })
            } else {
                let obj = this.state.params
                obj[name] = value
                this.setState({ params: obj })
            }
        } else {
            this.props[0].history.push(
                generateSearchLink(
                    this.props[0].location.search,
                    { entries: 10, page: 0 },
                    name,
                    value,
                    restart
                )
            )
        }
    }

    updateMultipleParams = (fields, restart = false) => {
        if (this.state.useState) {
            if (restart) {
                let obj = {
                    entries: 10,
                    page: 0,
                }
                for (let i = 0; i < fields.length; i++) {
                    obj[fields[i].name] = fields[i].value
                }
                this.setState({ params: obj })
            } else {
                let obj = this.state.params
                for (let i = 0; i < fields.length; i++) {
                    obj[fields[i].name] = fields[i].value
                }
                this.setState({ params: obj })
            }
        } else {
            this.props[0].history.push(
                generateSearchLinkMultiple(
                    this.props[0].location.search,
                    { entries: 10, page: 0 },
                    fields,
                    restart
                )
            )
        }
    }

    delete = (id) => {
        this.setState(
            {
                loading: true,
            },
            () => {
                fetch(API_ENDPOINT + '/data/directors/' + id, {
                    method: 'DELETE',
                    headers: {
                        'content-type': 'application/json',
                        Authorization:
                            typeof localStorage !== 'undefined'
                                ? `Bearer ${localStorage.getItem('authToken')}`
                                : null,
                    },
                })
                    .then((res) => res.json())
                    .then((result) => {
                        this.get()
                    })
            }
        )
    }
    import = () => {
        fetch(API_ENDPOINT + '/data/directors/import/new', {
            method: 'POST',
            headers: {
                'content-type': 'application/json',
                Authorization:
                    typeof localStorage !== 'undefined'
                        ? `Bearer ${localStorage.getItem('authToken')}`
                        : null,
            },
        })
            .then((res) => res.json())
            .then((result) => {
                this.get()
            })

    }
    setAbsence = (data) => {
    
        let obj = {
            startTS: Math.floor(this.state.startDate.getTime()/1000),
            endTS: Math.floor(this.state.endDate.getTime()/1000),
            user: data._id,
            username: data.username,
            status: 0,
            date: {
                range1: {
                    startDate: this.state.startDate,
                    endDate: this.state.endDate
                }
            }
        }
        
        fetch(API_ENDPOINT + '/data/absence/new', {
            method: 'POST',
            headers: {
                'content-type': 'application/json',
                Authorization:
                    typeof localStorage !== 'undefined'
                        ? `Bearer ${localStorage.getItem('authToken')}`
                        : null,
            },
            body: JSON.stringify(obj)
        })
            .then((res) => res.json())
            .then((result) => {
                this.props[0].history.push('/absence')
            })
    }

    render() {
        let params = {}
        if (this.state.useState) {
            params = this.state.params
        } else {
            params = getSearchParams(this.props[0].location.search, {
                entries: 10,
                page: 0,
            })
        }


        return (
            <div className='page'>
                <Container className='dashboard'>
                    <Row>
                        <Col lg='12'>
                            <Search updateParams={this.updateParams} />
                            <div className='panel'>
                                <div className='main-table-header'>
                                    <h5 className='component-header'>Lista</h5>

                                    <button className='add-news'>
                                        <Link to='/directors/new'>
                                            Dodaj
                                        </Link>
                                    </button>

                                    {/* <button className='add-news' onClick={() => this.import()}>Import</button> */}

                                </div>

                                <ListBuilder
                                    loading={this.state.loading && false}
                                    total={this.state.total}
                                    showNumeration={true}
                                    
                                    fields={[
                                        {
                                            type: 'text', class: 'labelbam', name: 'name', label: 'Ime i prezime', sortLabel: 'Po imenu', allowSort: true,
                                        },
                                        {
                                            type: 'text', class: 'labelbam', name: 'position', label: 'Pozicija', sortLabel: 'Po imenu', allowSort: true,
                                        },
                                       
                                    ]}
                                    rawItems={this.state.items}
                                    items={


                                        this.state.items.map((item, idx) => {
                                            return {
                                                ...item,
                                            }

                                        })

                                    }
                                    actions={[
                                       
                                        {
                                            type: 'functionAction',
                                            render: (item) => <Link to={
                                                `/directors/${item._id}`
                                            }><Isvg src={editIcon} />
                                            </Link>,
                                        },
                                        {
                                            component: <Isvg src={garabageIcon} className='edit-icon' />,
                                            onClick: (item) => this.setState({ deleteModal: item })
                                        },
                                    ]}
                                    params={params}
                                    sortField={params.sortField}
                                    sortType={params.sortType}
                                    updateSort={this.updateSort}
                                    updateParams={this.updateParams}
                                    updateMultipleParams={this.updateMultipleParams}
                                ></ListBuilder>
                                <DeleteModal
                                    isOpen={this.state.deleteModal}
                                    toggle={() => this.setState({ deleteModal: null })}
                                    handler={() => { this.delete(this.state.deleteModal._id); this.setState({ deleteModal: null }) }}
                                >
                                    Obrisi <strong>{this.state.deleteModal && this.state.deleteModal.name}</strong>?
                  </DeleteModal>
                                {
                                    this.state.absenceModal ?
                                        <Modal isOpen={this.state.absenceModal} toggle={this.state.absenceModal} className="absence-modal-wrap">
                                            <ModalHeader className="absence-modal">
                                                <h5>Unesite vrijeme odsustva za <span>{this.state.absenceModal.firstName} {this.state.absenceModal.lastName} ({this.state.absenceModal.username})</span></h5>
                                            </ModalHeader>
                                            <ModalBody className="absence-modal-body">
                                                <DateField value={this.state.startDate} label={'Od'} onChange={(e) => {
                                                    let ts = Math.floor(e.getTime() / 1000)
                                                    let endTs = Math.floor(this.state.endDate.getTime() / 1000)
                                                    if (ts < endTs)
                                                        this.setState({ startDate: e })
                                                    else
                                                        this.setState({ startDate: e, endDate: e })
                                                }} />
                                                <DateField value={this.state.endDate} label={'Do'} onChange={(e) => {

                                                    let ts = Math.floor(e.getTime() / 1000)
                                                    let startTs = Math.floor(this.state.startDate.getTime() / 1000)
                                                    if (ts > startTs)
                                                        this.setState({ endDate: e })
                                                    else
                                                        this.setState({ startDate: e, endDate: e })
                                                }} 
                                                />
                                            </ModalBody>
                                            <ModalFooter className="absence-modal-footer">
                                                <Button type="submit" color="danger" onClick={() => this.setAbsence(this.state.absenceModal)}>{'Potvrdi'.translate(this.props.lang)}</Button>{' '}
                                                <Button type="submit" color="primary" onClick={() => this.setState({ absenceModal: null })} >{'Odustani'.translate(this.props.lang)}</Button>{' '}
                                            </ModalFooter>
                                        </Modal>
                                        :
                                        null
                                }

                            </div>
                        </Col>
                    </Row>
                </Container>

            </div>
        )
    }
}

export default Page(List)
