
import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';

import Isvg from 'react-inlinesvg';

import { FormGroup, Label, Input, FormFeedback, FormText, Row, Col, Button } from 'reactstrap';


class Answers extends Component {
    constructor(props) {
        super(props);

        this.state = {
        };
    }






    render() {
        let value = this.props.value ? this.props.value : ['']
        return (
            <FormGroup>
                {this.props.label ? <Label size="sm" className={this.props.error ? "required" : ""}>{this.props.label}{false && this.props.haveValidation ? <span className="required-label-span">*</span> : null}</Label> : null}

                {
                    value.map((item, idx) => {
                        return (
                            <FormGroup>
                                <Input type="text" onChange={(e) => {
                                    value[idx] = e.target.value;
                                    this.props.onChange(value);
                                    this.forceUpdate();
                                }} value={item} />
                            </FormGroup>

                        )
                    })
                }
        <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                <Button size="sm" type="button" onClick={() => {
                    value.push('');
                    this.props.onChange(value);
                    this.forceUpdate();
                }}>Dodaj odgovor</Button>
                </div>
            </FormGroup>
        );
    }
}

export default Answers;