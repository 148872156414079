import HomePage from './views/homePage'

import Login from './views/loginPage'
import PreviewUserProfile from './views/previewUserProfile'


import { API_ENDPOINT } from './constants'

import news from './views/news'
import addNews from './views/addNews';
import addCategory from './views/addCategory';
import category from './views/category';




import AdminsList from './views/admins/list';
import AdminsForm from './views/admins/form';


import UsersList from './views/users/list';
import UsersForm from './views/users/form';

import DirectorsList from './views/directors/list';
import DirectorsForm from './views/directors/form';

import SettingsForm from './views/settings/form';
import PasswordsForm from './views/passwords/form';



function parseJSON(response) {
  return response.json().then((json) => {
    return {
      result: json,
      status: response.status,
    }
  })
}

export const routes = [
  {
    path: '/',
    component: HomePage,
    loginNeeded: true,
    exact: true,
    preAuthComponent: Login,
    generateSeoTags: (data, lang) => {
      return {
        title: 'EuroExpress'.translate(lang),
      }
    },
    loadData: [

    ],
    loadDataWithQuery: [

    ],
  },
  {
    path: '/profile/preview',
    component: PreviewUserProfile,
    // loginNeeded: false,
    // preAuthComponent: Login,
    generateSeoTags: (data, lang) => {
      return {
        title: 'EuroExpress'.translate(lang),
      }
    },
    loadData: [

    ],
    loadDataWithQuery: [

    ],
  },




  {
    path: '/admins/list',
    component: AdminsList,
    loginNeeded: true,
    preAuthComponent: Login,
    generateSeoTags: (data, lang) => {
      return {
        title: 'EuroExpress'.translate(lang),
      }
    },
    loadData: [],
    loadDataWithQuery: [
      (fetchFunction, lang, match, query) => {
        let queryFields = {};
        for (var key in query) {
          if (key && key.indexOf('query-') === 0 && query[key] != '0') {
            queryFields[key.replace('query-', '')] = query[key];
          }
        }

        return fetchFunction(API_ENDPOINT + '/data/admins', {
          method: 'POST',
          headers: {
            'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
            'content-type': 'application/json'
          },
          body: JSON.stringify({
            page: query.page,
            entries: query.entries,
            sortType: query.sortType,
            sortField: query.sortField,
            query: queryFields,
            filter: query.filter,
          })
        }).then(parseJSON).then(({ result, status }) => {
          if (status >= 200 && status < 300)
            return {
              items: result.items,
              total: result.total,
            }
        })

      },
    ],
  },
  {
    path: '/admins/:id',
    component: AdminsForm,
    loginNeeded: true,
    preAuthComponent: Login,
    generateSeoTags: (data, lang) => {
      return {
        title: 'EuroExpress'.translate(lang),
      }
    },
    loadData: [],
    loadDataWithQuery: [
      (fetchFunction, lang, match, query) => {
        return fetchFunction(API_ENDPOINT + '/data/menu/all', {
          method: 'GET',
          headers: {
            'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
            'content-type': 'application/json'
          },
        }).then(parseJSON).then(({ result, status }) => {
          if (status >= 200 && status < 300)
            return {
              menu: result,
            }
        })
      },
      (fetchFunction, lang, match, query) => {
        return fetchFunction(API_ENDPOINT + '/data/admins/' + match.params.id, {
          method: 'GET',
          headers: {
            'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
            'content-type': 'application/json'
          },
        }).then(parseJSON).then(({ result, status }) => {
          if (status >= 200 && status < 300)
            return {
              initialValues: result,
            }
        })
      }
    ],
  },


  {
    path: '/users/list',
    component: UsersList,
    loginNeeded: true,
    preAuthComponent: Login,
    generateSeoTags: (data, lang) => {
      return {
        title: 'EuroExpress'.translate(lang),
      }
    },
    loadData: [],
    loadDataWithQuery: [
      (fetchFunction, lang, match, query) => {
        let queryFields = {};
        for (var key in query) {
          if (key && key.indexOf('query-') === 0 && query[key] != '0') {
            queryFields[key.replace('query-', '')] = query[key];
          }
        }

        return fetchFunction(API_ENDPOINT + '/data/users', {
          method: 'POST',
          headers: {
            'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
            'content-type': 'application/json'
          },
          body: JSON.stringify({
            page: query.page,
            entries: query.entries,
            sortType: -1,
            sortField: '_id',
            query: queryFields,
            filter: query.filter,
          })
        }).then(parseJSON).then(({ result, status }) => {
          if (status >= 200 && status < 300)
            return {
              items: result.items,
              total: result.total,
            }
        })

      },
    ],
  },
  {
    path: '/users/:id',
    component: UsersForm,
    loginNeeded: true,
    preAuthComponent: Login,
    generateSeoTags: (data, lang) => {
      return {
        title: 'EuroExpress'.translate(lang),
      }
    },
    loadData: [],
    loadDataWithQuery: [
      (fetchFunction, lang, match, query) => {
        return fetchFunction(API_ENDPOINT + '/data/users/' + match.params.id, {
          method: 'GET',
          headers: {
            'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
            'content-type': 'application/json'
          },
        }).then(parseJSON).then(({ result, status }) => {
          if (status >= 200 && status < 300)
            return {
              initialValues: result,
            }
        })
      }
    ],
  },

  {
    path: '/directors/list',
    component: DirectorsList,
    loginNeeded: true,
    preAuthComponent: Login,
    generateSeoTags: (data, lang) => {
      return {
        title: 'EuroExpress'.translate(lang),
      }
    },
    loadData: [],
    loadDataWithQuery: [
      (fetchFunction, lang, match, query) => {
        let queryFields = {};
        for (var key in query) {
          if (key && key.indexOf('query-') === 0 && query[key] != '0') {
            queryFields[key.replace('query-', '')] = query[key];
          }
        }

        return fetchFunction(API_ENDPOINT + '/data/directors', {
          method: 'POST',
          headers: {
            'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
            'content-type': 'application/json'
          },
          body: JSON.stringify({
            page: query.page,
            entries: query.entries,
            sortType: -1,
            sortField: '_id',
            query: queryFields,
            filter: query.filter,
          })
        }).then(parseJSON).then(({ result, status }) => {
          if (status >= 200 && status < 300)
            return {
              items: result.items,
              total: result.total,
            }
        })

      },
    ],
  },
  {
    path: '/directors/:id',
    component: DirectorsForm,
    loginNeeded: true,
    preAuthComponent: Login,
    generateSeoTags: (data, lang) => {
      return {
        title: 'EuroExpress'.translate(lang),
      }
    },
    loadData: [],
    loadDataWithQuery: [
      (fetchFunction, lang, match, query) => {
        return fetchFunction(API_ENDPOINT + '/data/directors/' + match.params.id, {
          method: 'GET',
          headers: {
            'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
            'content-type': 'application/json'
          },
        }).then(parseJSON).then(({ result, status }) => {
          if (status >= 200 && status < 300)
            return {
              initialValues: result,
            }
        })
      }
    ],
  },



  {
    path: '/informations',
    component: SettingsForm,
    loginNeeded: true,
    preAuthComponent: Login,
    generateSeoTags: (data, lang) => {
      return {
        title: 'EuroExpress'.translate(lang),
      }
    },
    loadData: [
      (fetchFunction, lang, match, query) => {
        return fetchFunction(API_ENDPOINT + '/data/home', {
          method: 'GET',
          headers: {
            'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
            'content-type': 'application/json'
          },
        }).then(parseJSON).then(({ result, status }) => {
          if (status >= 200 && status < 300)

            return {
              initialValues: result,
            }
        })
      }
    ],
    loadDataWithQuery: [

    ],
  },
  {
    path: '/settings',
    component: PasswordsForm,
    loginNeeded: true,
    preAuthComponent: Login,
    generateSeoTags: (data, lang) => {
      return {
        title: 'EuroExpress'.translate(lang),
      }
    },
    loadData: [
      (fetchFunction, lang, match, query) => {
        return fetchFunction(API_ENDPOINT + '/data/settings', {
          method: 'GET',
          headers: {
            'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
            'content-type': 'application/json'
          },
        }).then(parseJSON).then(({ result, status }) => {
          if (status >= 200 && status < 300)
            console.log(result)
            return {
              initialValues: result,
            }
        })
      }
    ],
    loadDataWithQuery: [

    ],
  },
  




  {
    path: '/login',
    component: Login,
    redirectUser: '/',
    generateSeoTags: (data, lang) => {
      return {
        title: 'EuroExpress'.translate(lang),
      }
    },
    loadData: [],
  },




]

export function generateRoutes(r) {
  let newRoutes = []
  for (let i = 0; i < r.length; i++) {
    newRoutes.push(r[i])

    /*newRoutes.push({
            ...r[i],
            path: linkTranslates.en[r[i].path]
        })*/
  }
  return newRoutes
}
