import React, { Component, useState } from 'react'
import { Link, Redirect } from 'react-router-dom'
import { connect } from 'react-redux'

import Isvg from 'react-inlinesvg'

import {
  Container,
  Row,
  Col,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap'
import socketIO from 'socket.io-client'
import moment from 'moment'
import signal from '../assets/svg/signal.svg'
import noSignal from '../assets/svg/no-signal.svg'

import home from '../assets/svg/home.svg'
import devices from '../assets/svg/devices.svg'
import user from '../assets/svg/user.svg'
import log from '../assets/svg/log.svg'
import logout from '../assets/svg/logout.svg'
import secondarylogo from '../assets/svg/secondarylogo.svg'
import logo from '../assets/logo.svg'
import menuIcon from '../assets/svg/icon-menu.svg'
import homeIcon from '../assets/svg/home-icon.svg'
import commentIcon from '../assets/svg/comments-icon.svg'
import administratorIcon from '../assets/svg/administrator-icon.svg'
import moderatorIcon from '../assets/svg/moderator-icon.svg'
import editorIcon from '../assets/svg/editor-icon.svg'
import logoutIcon from '../assets/svg/logout-icon.svg'
import logoBottom from '../assets/svg/logo-bottom.svg'
import arrowDown from '../assets/svg/arrow-down.svg'
import listIcon from '../assets/svg/listnews-icon.svg'
import addIcon from '../assets/svg/addnews-icon.svg'

import listPageIcon from '../assets/svg/s1.svg'
import addPageIcon from '../assets/svg/s2.svg'
import contactIcon from '../assets/svg/s3.svg'
import settingsIcon from '../assets/svg/s5.svg'

moment.locale('sr')

import { API_ENDPOINT } from '../constants'

export class Header extends Component {
  constructor(props) {
    super(props)

    this.state = {
      active: false,
      show: false,

      menu: false,
      categoryNews: false,
      news: false,
      documents: false,
      pools: false,
      admins: false,
      users: false,
      settings: false,
      absences: false,
      suggestions: false,
      newEmployees: false,
      deliveryLines: false,
      library: false

    }
  }

  toggleClass = () => {
    const currentState = this.state.active
    this.setState({ active: !currentState })
  }
  toggleMenu = () => {
    const currentState = this.state.show
    this.setState({ show: !currentState })
  }
  logout = () => {

    localStorage.removeItem('authToken');
    this.props[0].history.push('/login');
    window.location.reload(false)

  }

  componentDidMount() {

    if (this.props.uData && this.props.uData.menuObjects && this.props.uData.menuObjects.length) {
      for (let i = 0; i < this.props.uData.menuObjects.length; i++) {
        if (this.props.uData.menuObjects[i].uniqueID == 1) {
          this.setState({
            categoryNews: true,
            news: true,

          })
        } else if (this.props.uData.menuObjects[i].uniqueID == 2) {
          this.setState({

            documents: true,
            // pools: false,
            absences: true,
            suggestions: true,
            newEmployees: true,
            users: true,
            settings: true

          })
        } else if (this.props.uData.menuObjects[i].uniqueID == 3) {
          this.setState({

            categoryNews: true,
            news: true,
            documents: true,

          })
        } else if (this.props.uData.menuObjects[i].uniqueID == 4) {
          this.setState({
            categoryNews: true,
            news: true,
            deliveryLines: true
          })
        }
      }
    }





  }


  render() {

    if (!this.props.uData) {
      return null
    }

    return (
      <header>
        <div className='sidebar'>
          <div className='sidebar-content'>

            <div className="sidebar-header">
              <a href="/">
                <div className='logo'>
                  <img src={logo} className='logo-image' />
                </div>
              </a>

              <ul className='sidebar-body'>
                {/* {
                  this.props.uData.super ?
                    <>

                    </>
                    :
                    null
                } */}


                <span className="menu-helper content-helper">Direktori</span>
                <li className='menu__item'>
                  <Link to='/directors/list' className='menu__link'>
                    <span>
                      <Isvg src={listPageIcon} />
                    </span>
                    <span className='menu__text'>Lista direktora</span>
                  </Link>
                </li>

                <li className='menu__item'>
                  <Link to='/directors/new' className='menu__link'>
                    <span>
                      <Isvg src={addPageIcon} />
                    </span>
                    <span className='menu__text'>Dodaj direktora</span>
                  </Link>
                </li>
                <span className="menu-helper content-helper"></span>


                <span className="menu-helper content-helper">Zaposleni</span>
                <li className='menu__item'>
                  <Link to='/users/list' className='menu__link'>
                    <span>
                      <Isvg src={listPageIcon} />
                    </span>
                    <span className='menu__text'>Lista zaposlenih</span>
                  </Link>
                </li>

                <li className='menu__item'>
                  <Link to='/users/new' className='menu__link'>
                    <span>
                      <Isvg src={addPageIcon} />
                    </span>
                    <span className='menu__text'>Dodaj zaposlenog</span>
                  </Link>
                </li>
                <span className="menu-helper content-helper"></span>
                <span className="menu-helper content-helper">Podesavanja</span>

                <li className='menu__item'>
                  <Link to='/settings' className='menu__link'>
                    <span >
                      <Isvg src={settingsIcon} />
                    </span>
                    <span className='menu__text'>Lozinke</span>
                  </Link>
                </li>

                <li className='menu__item'>
                  <Link to='/informations' className='menu__link'>
                    <span >
                      <Isvg src={settingsIcon} />
                    </span>
                    <span className='menu__text'>Podaci firme</span>
                  </Link>
                </li>


                <span className="menu-helper content-helper">ADMINISTRATORI</span>
                <li className='menu__item'>
                  <Link to='/admins/list' className='menu__link'>
                    <span>
                      <Isvg src={listPageIcon} />
                    </span>
                    <span className='menu__text'>Lista</span>
                  </Link>
                </li>
                <li className='menu__item'>
                  <Link to='/admins/new' className='menu__link'>
                    <span>
                      <Isvg src={addPageIcon} />
                    </span>
                    <span className='menu__text'>Dodaj</span>
                  </Link>
                </li>


                <span className="menu-helper content-helper"></span>


                <li className='menu__item'>
                  <div className='menu__link' onClick={this.logout}>
                    <span className="logout-svg">
                      <Isvg src={logoutIcon} />
                    </span>
                    <span className='menu__text'>Odjavi se</span>
                  </div>
                </li>
              </ul>
            </div>


            {/* <div className='sidebar-footer'>
              <Isvg src={logoBottom} className='footer-icon' />
            </div> */}

          </div>
        </div>
      </header>
    )
  }
}

export default Header
