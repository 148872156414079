import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import Header from '../components/header';


export const DefaultLayout = (Wrapped) => (props) => {
    return (
        <div>
            <div className={props[0].location.pathname == '/login' || props[0].location.pathname == '/profile/preview' ? 'home-content light' : 'home-content'}>
                {props[0].location.pathname != '/profile/preview' && props.uData ? <Header {...props} /> : null}

                <Wrapped
                    {...props}
                />
            </div>

        </div>
    );
};

export default DefaultLayout;