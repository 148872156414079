import React, { Component } from 'react'
import Isvg from 'react-inlinesvg'
import searchIcon from '../assets/svg/search.svg'



class Search extends Component {
  state = {
    query: '',
    results: [],
  }


  
  

  render() {
    return (
      <div>
        <input
          className='search-bar form-control'
          placeholder='Pretraži'
          ref={(input) => (this.search = input)}
          value={this.state.search}
          onChange={(e) => {
            this.setState({
              search: e.target.value
            }, () => {
              this.props.updateParams('filter', this.state.search)
            })
          }}

        ></input>
        <Isvg src={searchIcon} className='search-icon' />
      </div>
    )
  }
}

export default Search
