import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';

import Isvg from 'react-inlinesvg';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";



class Date extends Component {
    constructor(props) {
        super(props);

        this.state = {

        };
    }






    render() {
        return (
            <div style={{ display: 'flex', flexDirection: 'column', margin: '0 15px'}}>
                {this.props.label ? <label>{this.props.label}</label> : null}
                <DatePicker
                    onChange={this.props.onChange}
                    selected={this.props.value}
                    className="date-field"
                    // showTimeSelect
                    dateFormat="dd/MM/yyyy"
                    id={this.props.id}
                />

            </div>


        );
    }
}

export default Date;